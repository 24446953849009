export const links = {
	// bearerUrl: 'https://credite.test/api/v1',
	// galleryUrl: 'http://credite.test/storage',
	bearerUrl: 'https://backend.kcmfb.com/api/v1',
	galleryUrl: 'https://backend.kcmfb.com/storage',
	pages: {
		login: 'auth/login',
		register: 'auth/register',
		verify: 'auth/verify',
		generate_token: 'auth/generate_token',
		forget_password: 'auth/forget',
		reset_password: 'auth/reset',
		profile: 'agents/profile',
		profilePhoto: 'agents/profile-photo',
		updateProfile: 'agents/update-profile',
		offers: 'agents/offers',
		processOffer: 'agents/offers/action',
		logout: 'logout',
		loans: 'agents/loans',
		banks: 'list_banks',
		resolve_bank: 'validate_account',
		verifyTransaction: 'agents/tranx/payment_confirmation',
		pools: 'agents/pools',
		bookInvestment: 'agents/invest_now',
		investments: 'agents/investments',
		savings: 'agents/savings',
		verifyLoan: 'agents/loan-verification',
		topUp: 'agents/loan-topup',
		dashboard: 'agents/dashboard',
		verifyBvn: 'verify_bvn',
		verifyNin: 'verify_nin',
		investmentCert: 'agents/investments/preview',
		investmentLetter: 'agents/investments/preview/letter',
		checkCustomer: 'agents/check_customer',
		bookLoanViaCustomer: 'agents/create_loan_from_customer',
		uploadLoanDoc: 'agents/loans/upload-docs',
	},
	apis: {
		paystack: {
			public_key: 'pk_test_8df7558f5f78d926e1445f3122c268d7009be01a',
		},
		mono: {
			public_key: 'live_pk_N2Lx6IRNfE6BJWr1TLym',
		},
	},
};

export function getLink(link) {
	return `${links.bearerUrl}/${link}`;
}
